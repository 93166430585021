import { getSettings, updateSettings } from '../apis/common.apis'
import { useEffect, useState } from 'react'
import { useAuthStore } from '../store/authStore'
import { useSnackbarManager } from '../components/common/snackbar'

export default function SuperUserSettings() {
  const { isSuperUser } = useAuthStore()
  const [data, setData] = useState<any>()
  const getData = async () => {
    const data = await getSettings()
    setData(data?.settings)
    console.log(data)
  }

  useEffect(() => {
    if (!isSuperUser) {
      window.location.href = '/'
    } else {
      getData()
    }
  }, [])
  const { enqueueSnackbar } = useSnackbarManager()
  const handleChange = (e: any, item: any) => {
    const { checked } = e.target
    const request = {
      key: item.key,
      value: checked,
    }
    const updatedData = data?.map((dataItem: any) => ({
      ...dataItem,
      value: dataItem.key === item?.key ? checked : dataItem.value,
    }))
    setData(updatedData)
    updateSettings(request)
      .then(() => {
        enqueueSnackbar('Updated successfully', { variant: 'success' })
      })
      .catch((err: any) => {
        enqueueSnackbar(
          err?.response?.data?.error?.message || err?.response?.data?.message,
          { variant: 'error' }
        )
      })
  }

  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-2 ">
      <div className=" bg-primaryThin rounded-md p-4 flex flex-col items-center border shadow-sm justify-center gap-2 w-2/4 h-2/4">
        <h1 className=" text-lg font-semibold ">Super User Settings</h1>
        <div className="flex flex-col gap-2">
          {data?.map((item: any) => (
            <div key={item.key} className="flex  justify-start gap-2 relative">
              <div className={`custom-checkbox  relative `}>
                <input
                  type="checkbox"
                  name={item.key}
                  id={item.key}
                  checked={item.value}
                  onChange={(e) => {
                    handleChange(e, item)
                  }}
                  className={`custom-checkbox-input `}
                />
                <label htmlFor={item.key} className={` absolute top-1 left-0`}>
                  <svg
                    width="8"
                    height="6"
                    viewBox="0 0 8 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 3.21739L2.89883 6L8 1.06994L6.89494 0L2.89883 3.86768L1.09728 2.14745L0 3.21739Z"
                      fill="white"
                    />
                  </svg>
                </label>
                <span className=" ml-3">{item.label}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
