import { Link } from 'react-router-dom'

const Footer = ({ width }: { width?: string }) => {
  return (
    <footer
      className={`fixed bottom-0 right-0 px-8 py-2 bg-bgWhite z-[39]  border-t border-formBorder flex justify-center items-center ${width ? width : 'w-full'}`}
    >
      <Link
        to={'https://innovatedigital.io/'}
        target="_blank"
        className={
          'cursor-pointer text-secondary font-medium text-xs text-link break-all text-center hover:text-primary hover:underline '
        }
      >
        {'Powered by Innovate_'}
      </Link>
    </footer>
    // <footer className="fixed bottom-0 px-8 py-1 bg-bgWhite text-xs w-full border-t border-formBorder">
    //   <ul className="flex gap-3">
    //     {BreadCrumList.map(
    //       (item, index) =>
    //         index > 0 && (
    //           <li key={index} className="flex gap-3 items-center">
    //             <span>{item.name}</span>
    //             <div className="h-4 w-4">
    //               <Icons name="breadcrumbs-arrow" />
    //             </div>
    //           </li>
    //         )
    //     )}
    //   </ul>

    //   {/* <CustomBreadCrumbs/> */}
    // </footer>
  )
}

export default Footer
