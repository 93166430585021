const LOGIN_URL = 'login'

const NOTIFICATION_LIST = 'notifications/web'
const NOTIFICATION_CLEAR = 'notifications/web/clear_all'
const ASSESSOR_LIST_URL = 'assessors'
const ADMIN_USER = 'employees'
const GROUP_LIST = 'groups'
const ORGANISATION_LIST_URL = 'organisations'
const ORGANISATION_URL = 'organisation'
const MY_PROFILE = 'profile'
const MY_PROFILE_IMAGE = 'profile-image'

const COUNTRIES = 'countries'
const ORGANISATION_DATATABLES = 'datatable'

const ACCREDITATION_LIST_URL = 'accreditations'
const RENEW_APPLICATION = 'renew-accreditations'
const ADMIN_EXPORT = 'employees/export'
const ASSESSOR_EXPORT = 'assessors/export'
const ORGANISATION_EXPORT = 'organisations/export'
const ACCREDITATION_EXPORT = 'accreditations/export'

const SETTINGS = 'core/get-settings'
const UPDATE_SETTINGS = 'core/update-settings'
const apiUrl: { [key: string]: string } = {
  LOGIN_URL,
  NOTIFICATION_LIST,
  NOTIFICATION_CLEAR,
  ASSESSOR_LIST_URL,
  ADMIN_USER,
  GROUP_LIST,
  ORGANISATION_LIST_URL,
  MY_PROFILE,
  MY_PROFILE_IMAGE,
  COUNTRIES,
  ORGANISATION_URL,
  ORGANISATION_DATATABLES,
  ACCREDITATION_LIST_URL,
  RENEW_APPLICATION,
  ADMIN_EXPORT,
  ASSESSOR_EXPORT,
  ORGANISATION_EXPORT,
  ACCREDITATION_EXPORT,
  SETTINGS,
  UPDATE_SETTINGS,
}

export default apiUrl
