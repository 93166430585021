import React from 'react'

import { IconProps } from '../../../common/types'

const ArrowUp: React.FC<IconProps> = () => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33337 5.99998L6.00004 1.33331L10.6667 5.99998"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowUp
