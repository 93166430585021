// import { parseQueryParams } from '../utilities/parsers'
import { parseQueryParams } from '../utilities/parsers'
import { downloadData, getData, postData, updateFromData } from './api.helpers'
import apiUrl from './api.url'

// const buildUrlWithParams = (baseUrl: string, params: any) => {
//   return `${baseUrl}${parseQueryParams(params)}`
// }
export const getImpersonate = (id: string) => {
  return getData(`${apiUrl.EMPLOYEE_LIST_URL}permissions/${id}/`)
}

export const getCountries = () => getData(apiUrl.COUNTRIES)

export const updateAdminPassword = (employee: string, data: string) => {
  return updateFromData(
    `${apiUrl.ADMIN_USER}/${employee}/change_password`,
    data
  )
}

export const updateAssessorPassword = (id: string, data: string) => {
  return updateFromData(
    `${apiUrl.ASSESSOR_LIST_URL}/${id}/change_password`,
    data
  )
}

export const exportData = (input: any, url: any, type?: any) => {
  // if (type === 'renew') {
  //   input.is_renewal = 'true'
  //   input.is_resubmission = 'false'
  // } else if (type === 'new') {
  //   input.is_renewal = 'false'
  //   input.is_resubmission = 'false'
  // } else if (type === 'resubmission') {
  //   input.is_resubmission = 'true'
  // } else if (type === 'pending') {
  //   input.is_confirmed = 'true'
  //   input.is_verified = 'false'
  // }
  switch (type) {
    case 'renew':
      input.is_renewal = 'true'
      input.is_resubmission = 'false'
      break

    case 'new':
      input.is_renewal = 'false'
      input.is_resubmission = 'false'
      break

    case 'resubmission':
      input.is_resubmission = 'true'
      break

    case 'pending':
      input.is_confirmed = 'True'
      input.is_verified = 'False'
      break

    default:
      break
  }

  return downloadData(`${apiUrl[url]}${parseQueryParams(input)}`)
}

export const getSettings = () => {
  return getData(`${apiUrl.SETTINGS}`)
}
export const updateSettings = (data: any) => {
  return postData(`${apiUrl.UPDATE_SETTINGS}`, data)
}
