import React from 'react'

import { IconProps } from '../../../common/types'

const ExclamationIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1523 10C18.1523 14.4183 14.5706 18 10.1523 18C5.73407 18 2.15234 14.4183 2.15234 10C2.15234 5.58172 5.73407 2 10.1523 2C14.5706 2 18.1523 5.58172 18.1523 10ZM11.1523 14C11.1523 14.5523 10.7046 15 10.1523 15C9.60006 15 9.15234 14.5523 9.15234 14C9.15234 13.4477 9.60006 13 10.1523 13C10.7046 13 11.1523 13.4477 11.1523 14ZM10.1523 5C9.60006 5 9.15234 5.44772 9.15234 6V10C9.15234 10.5523 9.60006 11 10.1523 11C10.7046 11 11.1523 10.5523 11.1523 10V6C11.1523 5.44772 10.7046 5 10.1523 5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ExclamationIcon
