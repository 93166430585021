// import AssessorSummary from '../pages/assessor/details/summary'
import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

// import TabularLoader from '../components/common/tabularLoader'
import { router_config } from '../configs/route.config'
import NotFoundPage from '../pages/404'
// import Accreditation from '../pages/accreditation'
// import AccreditationCertification from '../pages/accreditation/certification'
// import AccreditationDetails from '../pages/accreditation/details'
// import ApplicationForm from '../pages/accreditation/details/applicationForm'
// import Info from '../pages/accreditation/details/info'
// import AccreditaitionDetails from '../pages/accreditaitonDetails'
// import AccreditationList from '../pages/accreditationList'
// import Accreditation from '../pages/accreditation'
// import AdminUser from '../pages/AdminUser'
// import { TabularForm } from '../pages/tabularform'
// import ChangeLog from '../pages/changeLog'
// import Profile from '../pages/profile'
// import Profile from '../pages/profile'
// import Profile from '../pages/profile'
import TabularForm from '../pages/tabularform'
import ResetPassword from '../pages/userManagement/resetPasswords'
import { useDomainManageStore } from '../store/domainManageStore'
// import ResetPassword from '../pages/userManagement/resetpassword'
import ChildRoute from './components/childRoute'
// import ChildRoute from './components/childRoute'
import GuestRoute from './components/guestRoute'
import UserRoute from './components/userRoute'
import SuperUserSettings from '../pages/superUserSettings'

const Login = lazy(() => import('../pages/userManagement/login'))
const ForgetPassword = lazy(
  () => import('../pages/userManagement/forgetPassword')
)

// Assessor

const Assessor = lazy(() => import('../pages/assessor'))

const Accreditation = lazy(() => import('../pages/accreditation'))
const AccreditationCertification = lazy(
  () => import('../pages/accreditation/certification')
)
const AccreditationDetails = lazy(
  () => import('../pages/accreditation/details')
)
// const ApplicationForm = lazy(
//   () => import('../pages/accreditation/details/applicationForm')
// )
// const Info = lazy(() => import('../pages/accreditation/details/info'))
const AdminUser = lazy(() => import('../pages/AdminUser'))

const Organisation = lazy(() => import('../pages/organisation'))
const OrganisationDetails = lazy(() => import('../pages/organisation/details'))
const OrganisationProfiles = lazy(
  () => import('../pages/organisation/details/profile')
)
const OrganisationUsers = lazy(
  () => import('../pages/organisation/details/user')
)
const AccreditationEligibility = lazy(
  () => import('../pages/organisation/details/eligibility')
)
const OrganisationDEIStrategy = lazy(
  () => import('../pages/organisation/details/dei-strategy')
)
const OrganisationDatatables = lazy(
  () => import('../pages/organisation/details/datatables')
)
const OrganisationFileRepository = lazy(
  () => import('../pages/organisation/details/file-repository')
)
const OrganisationChangeLog = lazy(
  () => import('../pages/organisation/details/change-log')
)

// Dashboard

const Dashboard = lazy(() => import('../pages/dashboard/dashboard'))

const routes: any = [
  { slug: 'DASHBOARD', component: <Dashboard /> },
  { slug: 'TABULARFORM', component: <TabularForm /> },
  {
    slug: 'ACCREDITAITON_CERTIFICATION',
    component: <AccreditationCertification />,
  },
  { slug: 'ACCREDITAITON', component: <Accreditation /> },

  {
    isAuthRoute: true,
    slug: 'LOGIN',
    component: <Login />,
  },

  {
    isAuthRoute: true,
    slug: 'FORGET_PASSWORD',
    component: <ForgetPassword />,
  },
  {
    isAuthRoute: true,
    slug: 'RESET_PASSWORD',
    component: <ResetPassword />,
  },
  // {
  //   slug: 'USER_PROFILE',
  //   component: <Profile />,
  // },

  {
    slug: 'ASSESSOR',
    component: <Assessor />,
    isPrimary: true,
  },
  // {
  //   slug: 'CONTACT_MERGE',
  //   component: <ContactMerge />,
  // },
  // {
  //   slug: 'CONTACT_DETAILS_MERGE',
  //   component: <ContactMerge />,
  // },

  // {
  //   slug: 'ASSESSOR_DETAILS',
  //   component: <AssessorDetails />,
  //   children: [
  //     {
  //       slug: 'ASSESSOR_DETAILS_SUMMARY',
  //       component: <AssessorSummary />,
  //     },

  //   ],
  // },

  {
    slug: 'ORGANISATION',
    component: <Organisation />,
    isPrimary: true,
  },
  {
    slug: 'HOME',
    component: <Accreditation />,
    isPrimary: true,
  },
  {
    slug: 'MY_ORGANISATION_DETAILS',
    component: <OrganisationDetails fromOrg={true} />,
    isPrimary: true,

    children: [
      {
        slug: 'ORGANISATION_DETAILS_PROFILES',
        component: <OrganisationProfiles fromOrg={true} />,
      },
      {
        slug: 'ORGANISATION_DETAILS_USERS',
        component: <OrganisationUsers fromOrg={true} />,
      },
      {
        slug: 'ACCREDITAITON_ELIGIBILITY',
        component: <AccreditationEligibility fromOrg={true} />,
      },
      {
        slug: 'ORGANISATION_DEI_STRATEGY',
        component: <OrganisationDEIStrategy fromOrg={true} />,
      },
      {
        slug: 'ORGANISATION_DATATABLES',
        component: <OrganisationDatatables fromOrg={true} />,
      },
      {
        slug: 'ORGANISATION_FILE_REPOSITORY',
        component: <OrganisationFileRepository fromOrg={true} />,
      },
      {
        slug: 'ORGANISATION_CHANGE_LOG',
        component: <OrganisationChangeLog fromOrg={true} />,
      },
    ],
  },
  {
    slug: 'ORGANISATION_DETAILS',
    component: <OrganisationDetails />,
    children: [
      {
        slug: 'ORGANISATION_DETAILS_PROFILES',
        component: <OrganisationProfiles />,
      },
      {
        slug: 'ORGANISATION_DETAILS_USERS',
        component: <OrganisationUsers />,
      },
      {
        slug: 'ACCREDITAITON_ELIGIBILITY',
        component: <AccreditationEligibility />,
      },
      {
        slug: 'ORGANISATION_DEI_STRATEGY',
        component: <OrganisationDEIStrategy />,
      },
      {
        slug: 'ORGANISATION_DATATABLES',
        component: <OrganisationDatatables />,
      },
      {
        slug: 'ORGANISATION_FILE_REPOSITORY',
        component: <OrganisationFileRepository />,
      },
      {
        slug: 'ORGANISATION_CHANGE_LOG',
        component: <OrganisationChangeLog />,
      },
    ],
  },
  {
    slug: 'ACCREDITAITON_DETAILS',
    component: <AccreditationDetails />,
    isPrimary: true,

    // children: [
    //   {
    //     slug: 'ACCREDITAITON_DETAILS_INFO',
    //     component: <Info />,
    //   },
    //   {
    //     slug: 'ACCREDITAITON_DETAILS_FORM',
    //     component: <ApplicationForm />,
    //   },
    // ],
  },
  { slug: 'ADMIN_USER', component: <AdminUser /> },
  { slug: 'NOTFOUND', component: <NotFoundPage /> },
  { slug: 'SUPER_USER_SETTINGS', component: <SuperUserSettings /> },
]

export default function MainRoutes() {
  const { domainType } = useDomainManageStore()

  const handleDefaultRoute = () => {
    if (domainType === 'Organisation') {
      return <Navigate to={'/myorganisation/profile'} replace />
    } else if (domainType === 'Assessor') {
      return <Navigate to={'/assessors'} replace />
    } else {
      return <Navigate to={'/admin-user'} replace />
    }
  }
  return (
    <Routes>
      {routes.map((route: any) => (
        <React.Fragment key={route.slug}>
          {route.isAuthRoute ? (
            <Route
              element={<GuestRoute>{route.component}</GuestRoute>}
              path={router_config[route.slug]?.path}
              key={router_config[route.slug]?.path}
            />
          ) : (
            <Route
              element={
                <UserRoute slug_key={route.slug}>{route.component}</UserRoute>
              }
              path={router_config[route.slug]?.path}
              key={router_config[route.slug]?.path}
            >
              {route.children?.length && (
                <>
                  {route.children.map((child: any) => (
                    <Route
                      element={
                        <ChildRoute slug_key={child.slug}>
                          {child.component}
                        </ChildRoute>
                      }
                      path={router_config[child.slug]?.path}
                      key={router_config[child.slug]?.path}
                    />
                  ))}
                </>
              )}
            </Route>
          )}
        </React.Fragment>
      ))}

      <Route
        element={<UserRoute slug_key="">{handleDefaultRoute()} </UserRoute>}
        key="*"
        path="/"
      />
      <Route
        element={
          <UserRoute slug_key="">
            <Navigate to="/404" replace />
          </UserRoute>
        }
        key="*"
        path="*"
      />
      <Route
        element={<UserRoute slug_key="">{handleDefaultRoute()}</UserRoute>}
        key="*"
        path="/"
      />
    </Routes>
  )
}
